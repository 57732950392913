import clsx from 'clsx';
import { $, component$, useSignal, useTask$ } from '@builder.io/qwik';
import { Button } from '../ui/Button';
import {
  FormError,
  formAction$,
  reset,
  useForm,
  valiForm$
} from '@modular-forms/qwik';
import { Input } from '../ui/Input';
import {
  type Input as ValibotInput,
  boolean,
  merge,
  minLength,
  object,
  optional,
  string
} from 'valibot';
import { algoliaClient } from '../../lib/algolia.lib';
import { signUpWithEmail } from '../../lib/auth';
import { signinSchema } from './SigninEmailForm';
import { useAuthDataLoader } from './utils';
import { useSetupModal } from '../setup/SetupModal';

export type SignupForm = ValibotInput<typeof signupSchema>;

export const signupSchema = merge([
  signinSchema,
  object({
    name: string([minLength(1, 'Por favor informe seu nome')]),
    terms: optional(boolean()),
    privacy: optional(boolean())
  })
]);

export const useSignupFormAction = formAction$<SignupForm>(
  async (values, ctx) => {
    try {
      const auth = await signUpWithEmail(
        values.email,
        values.password,
        values.name,
        ctx.url.hostname
      );

      if (!auth.user) {
        throw 'No user';
      }

      for (const cookie of auth.cookies) {
        cookie.value
          ? ctx.cookie.set(cookie.name, cookie.value, cookie.options)
          : ctx.cookie.delete(cookie.name, cookie.options);
      }
    } catch {
      throw new FormError<SignupForm>(
        'Um erro inesperado ocorreu. Por favor, entre em contato com o suporte!'
      );
    }
  },
  valiForm$(signupSchema)
);

export const SignupEmailForm = component$(
  ({ onSuccess$, reload }: { onSuccess$?: () => void; reload?: boolean }) => {
    const { open: openSetupModal } = useSetupModal();
    const { value: data } = useAuthDataLoader();
    const initialState = useSignal({
      name: '',
      email: '',
      password: '',
      redirectUrl: undefined,
      terms: false,
      privacy: false
    });

    const action = useSignupFormAction();

    const [state, { Form, Field }] = useForm<SignupForm>({
      action,
      loader: initialState,
      validateOn: 'touched',
      validate: valiForm$(signupSchema)
    });

    useTask$(({ track }) => {
      const status = track(() => action.status);

      if (state.invalid || status !== 200 || typeof onSuccess$ !== 'function') {
        return;
      }

      reset(state);

      algoliaClient('sendEvents', [
        {
          eventName: 'account created',
          eventType: 'conversion',
          index: 'calliope_auto_index',
          objectIDs: [],
          userToken: window.algoliaUserToken,
          authenticatedUserToken: window.algoliaUserToken
        }
      ]);

      if (reload) {
        window.location.href = window.location.href.split('#')[0];
      } else {
        onSuccess$();
        openSetupModal();
      }
    });

    return (
      <div class="relative flex w-full flex-col items-center justify-start gap-5 mt-4">
        <div class="flex flex-col gap-7 self-stretch">
          <Form class="flex w-full flex-col gap-5">
            <Field name="name">
              {(field, props) => (
                <Input
                  {...props}
                  class={clsx(
                    !state.submitted &&
                      'focus:ring-gray-300 focus:border-gray-300',
                    'focus:placeholder-neutral-300'
                  )}
                  autoComplete="name"
                  disabled={state.submitting}
                  placeholder="Seu nome*"
                  size="lg"
                  state={
                    field.touched && state.invalid
                      ? field.error
                        ? 'error'
                        : 'success'
                      : 'default'
                  }
                  value={field.value}
                />
              )}
            </Field>
            <Field name="email">
              {(field, props) => (
                <Input
                  {...props}
                  class={clsx(
                    !state.submitted &&
                      'focus:ring-gray-300 focus:border-gray-300',
                    'focus:placeholder-neutral-300'
                  )}
                  autoComplete="username"
                  disabled={state.submitting}
                  placeholder="Seu email*"
                  size="lg"
                  state={
                    field.touched && state.invalid
                      ? field.error
                        ? 'error'
                        : 'success'
                      : 'default'
                  }
                  type="email"
                  value={field.value}
                />
              )}
            </Field>
            <Field name="password">
              {(field, props) => (
                <Input
                  {...props}
                  class={clsx(
                    !state.submitted &&
                      'focus:ring-gray-300 focus:border-gray-300',
                    'focus:placeholder-neutral-300'
                  )}
                  autoComplete="current-password"
                  disabled={state.submitting}
                  helpText="● Mínimo de 8 caracteres"
                  placeholder="Sua senha*"
                  size="lg"
                  state={
                    field.touched && state.invalid
                      ? field.error
                        ? 'error'
                        : 'success'
                      : 'default'
                  }
                  value={field.value}
                  password
                />
              )}
            </Field>
            <Field name="redirectUrl">
              {(field, props) => (
                <input
                  {...props}
                  disabled={state.submitting}
                  type="hidden"
                  value={field.value}
                />
              )}
            </Field>
            <Button
              class="w-full self-center"
              disabled={state.validating || state.invalid || state.submitting}
              loading={state.submitting}
              size="lg"
              type="submit"
              variant="brand"
              onClick$={$(() => {
                if (window?.dataLayer) {
                  window['dataLayer'].push({
                    event: 'sign_up',
                    method: 'Formulário',
                    is_checkout: false
                  });
                }
              })}
            >
              {data.signup.buttonTitle}
            </Button>
          </Form>
        </div>
      </div>
    );
  }
);
